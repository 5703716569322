<template>
  <b-modal
    @shown="updateItem"
    title="新增分期還款紀錄"
    id="repayRecordAdd"
    size="lg"
  >
    <b-container fluid>
      <main class="dtc-grid-RecordAdd">
        <b-input-group prepend="清償期別">
          <b-input
            v-model="period"
            placeholder="請輸入清償期別"
            readonly
          ></b-input>
        </b-input-group>
        <b-input-group prepend="繳納日期">
          <div class="sign-date">
            <b-input
              v-model.trim="date.payYear"
              placeholder="民國年"
              type="number"
              min="1"
              @keypress="isNumber($event)"
            ></b-input>
            <span class="dash">-</span>
            <b-input
              v-model.trim="date.payMonth"
              placeholder="月"
              type="number"
              max="12"
              min="1"
              @keypress="isNumber($event)"
            ></b-input>
            <span class="dash">-</span>
            <b-input
              v-model.trim="date.payDay"
              type="number"
              max="31"
              min="1"
              placeholder="日"
              @keypress="isNumber($event)"
            ></b-input>
          </div>
        </b-input-group>
        <b-input-group prepend="繳納金額">
          <b-input v-model="amount" placeholder="請輸入繳納金額"></b-input>
        </b-input-group>
        <b-input-group prepend="繳納方式">
          <b-input
            v-model="way"
            placeholder="請輸入繳納方式"
            readonly
          ></b-input>
        </b-input-group>
        <b-input-group prepend="收據號碼" style="grid-column:1/-1;">
          <b-input
            v-model="receiptNumber"
            placeholder="請輸入收據號碼"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="備註" style="grid-column:1/-1;">
          <b-input v-model="remark" placeholder="請輸入備註"></b-input>
        </b-input-group>
      </main>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3 mr-3"
          @click="$bvModal.hide('repayRecordAdd')"
          >取消</b-button
        >
        <b-button
          variant="success"
          class="float-right ml-3"
          @click="addRecordData"
          >儲存</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
import moment from "moment";

export default {
  name: "RepayRecordAdd",
  data() {
    return {
      item: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      period: "",
      date: {
        payYear: +moment(Date.now()).format("YYYY") - 1911,
        payMonth: +moment(Date.now()).format("MM"),
        payDay: "",
      },
      amount: "",
      way: "匯款",
      receiptNumber: "",
      remark: "",
      seqNum: "",
      totalMoney: "",
      firstFee: "",
    };
  },
  computed: {},
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateItem() {
      this.item = { ...store.editItem };
      this.amount =
        +this.item.nowAmount > 0
          ? this.item.allItem.Month_Fee
          : this.item.allItem.First_Fee;
      this.date.payDay = this.item.allItem.Rday;
      this.period = +this.item.nowAmount + 1;
      this.seqNum = this.item.allItem.Seqno;
      this.totalMoney = this.item.allItem.TotalMoney;
      this.firstFee = this.item.allItem.First_Fee;
      this.receiptNumber = "";
      this.remark = "";
    },
    async addRecordData() {
      const obj2 = {
        Seqno: this.seqNum,
        Times: this.period,
        Pay_Fee: +this.amount.replace(/,/g, ""),
        Pay_Date: this.$usDate(
          `${this.date.payYear}-${this.date.payMonth}-${this.date.payDay}`
        ),
        Pay_Kind: "匯款",
        // Fee_2: this.remainMoney,
        AccNo: this.receiptNumber,
        Commet: this.remark,
      };

      try {
        await window.axios.post("StudentInfo/PostRepaymentDetail", obj2);
        this.$emit("update2");
        this.$bvModal.hide("repayRecordAdd");
        this.$bvToast.toast(`新增成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast(e + `新增失敗`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  mounted() {},
  watch: {
    "date.payYear"(v) {
      if (+v < 0) {
        this.date.payYear = "0";
      }
      let value = `${v}`;
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (v.length > 3) {
        value = value.substring(1, 4);
      }
      const result = value;
      this.$nextTick(() => (this.date.payYear = result));
    },
    "date.payMonth"(v) {
      let value = `${v}`;
      if (+v < 0) {
        this.date.payMonth = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v == 10) {
        value = 10;
      } else if (+v == 11) {
        value = 11;
      } else if (+v >= 12) {
        value = 12;
      }
      const result = value;
      this.$nextTick(() => (this.date.payMonth = result));
    },
    "date.payDay"(v) {
      let value = `${v}`;
      if (+v < 0) {
        this.date.payDay = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v >= 31) {
        value = 31;
      }
      const result = value;
      this.$nextTick(() => (this.date.payDay = result));
    },
    amount(v) {
      if (v) {
        let value = `${v}`;
        if (v.length > 10) {
          value = value.substring(0, 10);
        }

        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        const result = value
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.$nextTick(() => (this.amount = result));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-grid-RecordAdd {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 6px;
}

p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 100px;
  background: #0379fd;
  color: #fff;
}

div.input-group.special-col > div.input-group-prepend > div.input-group-text {
  width: 130px;
}
div.input-group.dtc-bg > div.input-group-prepend > div.input-group-text {
  background: #646969;
  color: #ffffff;
}

.input-group {
  margin-bottom: 10px;
}

::v-deep #repayRecordAdd > .modal-dialog > .modal-content > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}

hr.new {
  border-top: 1px dashed #cacaca;
}
h4.title {
  font-size: 20px;
  font-weight: 700;
}
.sign-date {
  display: grid;
  grid-template-columns: 88px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
</style>
