<template>
  <b-modal
    @shown="updateItem"
    :title="
      `公費生請領費用明細 - 編號:${personinfo.code}    身分證號:${personinfo.id}    姓名:${personinfo.name}`
    "
    id="feeClaimDetail"
    size="xl"
  >
    <template #modal-title
      >公費生請領費用明細 -
      <span class="mr-3">編號: {{ personinfo.code }}</span>
      <span class="mr-3">身分證號: {{ personinfo.id }}</span>
      <span>姓名: {{ personinfo.name }}</span></template
    >
    <b-container fluid>
      <header class="dtc-grid-header my-dark">
        <div
          v-for="(item, i) in headers"
          :key="`headers${i}`"
          :title="item"
          style="background:#424242;color:#fff"
        >
          {{ item }}
        </div>
      </header>
      <main
        v-if="!items.length"
        class="mt-2"
        style="grid-column: 1/-1;font-size:26px;text-align:center;font-weight:600"
      >
        暫無資料
      </main>
      <main
        class="dtc-grid-header2"
        v-for="(item, i) in items"
        :key="i"
        style="color:#39312E;"
        :style="
          i % 2 == 0
            ? 'background-color: #EDEDED;'
            : 'background-color: #DDDDDD;'
        "
      >
        <div>{{ i + 1 }}</div>
        <div :title="item.Byear">{{ item.Byear || "暫無資料" }}</div>
        <div :title="item.Term">{{ item.Term || "暫無資料" }}</div>
        <div :title="item.SchoolName">{{ item.SchoolName || "暫無資料" }}</div>
        <div :title="item.MajorName">{{ item.MajorName || "暫無資料" }}</div>
        <div :title="item.GradeName">{{ item.GradeName || "暫無資料" }}</div>
        <div :title="item.Fee_1" class="fee">
          {{ $formatPrice(item.Fee_1) || "暫無資料" }}
        </div>
        <div :title="item.Fee_2" class="fee">
          {{ $formatPrice(item.Fee_2) || "暫無資料" }}
        </div>
        <div :title="item.Fee_3" class="fee">
          {{ $formatPrice(item.Fee_3) || "暫無資料" }}
        </div>
        <div :title="item.Fee_4" class="fee">
          {{ $formatPrice(item.Fee_4) || "暫無資料" }}
        </div>
        <div :title="item.Fee_5" class="fee">
          {{ $formatPrice(item.Fee_5) || "暫無資料" }}
        </div>
        <div :title="item.Subtotal" class="fee">
          {{ $formatPrice(item.Subtotal) || "暫無資料" }}
        </div>
      </main>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3 mr-3"
          @click="$bvModal.hide('feeClaimDetail')"
          >關閉</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
const headers = [
  "序號",
  "學年度",
  "學期",
  "學校",
  "學系",
  "年級",
  "註冊費",
  "生活費",
  "應屆畢業生旅行參訪費",
  "旅宿費",
  "設備費",
  "小計",
];
const numberOption = [
  {
    value: 1,
    text: "一",
  },
  {
    value: 2,
    text: "二",
  },
  {
    value: 3,
    text: "三",
  },
  {
    value: 4,
    text: "四",
  },
  {
    value: 5,
    text: "五",
  },
  {
    value: 6,
    text: "六",
  },
  {
    value: 7,
    text: "七",
  },
  {
    value: 8,
    text: "八",
  },
  {
    value: 9,
    text: "九",
  },
];
import { store } from "@/store/global.js";
import moment from "moment";

export default {
  name: "feeClaimDetail",
  data() {
    return {
      personinfo: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headers,
      items: [],
      numberOption,
    };
  },
  computed: {},
  methods: {
    updateItem() {
      this.personinfo = { ...store.editItem };

      this.getData();
    },
    async getData() {
      const id = store.editItem.id;
      const url = `StudentInfo/GetBudgetDetail?identifier=${id}`;

      try {
        const res = await window.axios.get(url);
        this.items = res ? [...res] : [];
      } catch (e) {
        console.log(e);
      }
    },
  },

  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns:
    80px 80px 80px 160px 160px 80px repeat(2, 120px) 200px repeat(2, 120px)
    1fr;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    // word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    word-break: keep-all;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-top: 1px solid #dee2e5;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 0;
    line-height: 46px;
    height: 46px;
  }
  .fee {
    text-align: right;
    padding-right: 10px;
  }
}
p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 100px;
  background: #0379fd;
  color: #fff;
}

div.input-group.special-col > div.input-group-prepend > div.input-group-text {
  width: 130px;
}
div.input-group.dtc-bg > div.input-group-prepend > div.input-group-text {
  background: #646969;
  color: #ffffff;
}

.input-group {
  margin-bottom: 10px;
}

::v-deep #feeClaimDetail > .modal-dialog > .modal-content > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
::v-deep .modal-content {
  margin-left: -180px;
  width: 136%;
}
.form-control[readonly] {
  background-color: #ffffff;
}
hr.new {
  border-top: 1px dashed #cacaca;
}
h4.title {
  font-size: 20px;
  font-weight: 700;
}
</style>
