<template>
  <b-modal
    @shown="updateItem"
    title="公費生分期還款契約書紀錄表"
    id="contractrepayrecord"
    size="xl"
  >
    <ContractRepayRecordAdd @update2="getData"></ContractRepayRecordAdd>
    <ContractRepayRecordEdit @update="getData"></ContractRepayRecordEdit>
    <b-container fluid>
      <h4 class="title">分期還款繳納紀錄</h4>
      <main class="main-grid-dtc">
        <b-input-group prepend="姓名">
          <b-input v-model="name" placeholder="請輸入姓名" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="身分證字號">
          <b-input
            v-model="id"
            placeholder="請輸入身分證字號"
            readonly
          ></b-input>
        </b-input-group>
        <b-input-group prepend="保證人">
          <b-input v-model="guarantor" placeholder="保證人"></b-input>
        </b-input-group>
        <b-input-group prepend="第二保證人">
          <b-input v-model="guarantorSecond" placeholder="選填"></b-input>
        </b-input-group>
        <b-input-group prepend="監護人">
          <b-input v-model="guardian" placeholder="監護人"></b-input>
        </b-input-group>
        <b-input-group prepend="契約簽訂日">
          <div class="sign-date">
            <b-input
              type="number"
              min="1"
              v-model.trim="signingYear"
              placeholder="民國年"
              @keypress="isNumber($event)"
            ></b-input>
            <span class="dash">-</span>
            <b-input
              v-model.trim="signingMonth"
              placeholder="月"
              type="number"
              max="12"
              min="1"
              @keypress="isNumber($event)"
            ></b-input>
            <span class="dash">-</span>
            <b-input
              v-model.trim="signingDay"
              placeholder="日"
              type="number"
              max="31"
              min="1"
              @keypress="isNumber($event)"
            ></b-input>
          </div>
        </b-input-group>
      </main>
      <main class="main-grid-dtc" style="height:48px">
        <b-input-group prepend="核定文號">
          <b-input v-model="approvedNumber" placeholder="核定文號"></b-input>
        </b-input-group>
        <b-input-group prepend="核定日期">
          <div class="sign-date">
            <b-input
              v-model.trim="approvedYear"
              placeholder="民國年"
              type="number"
              min="1"
              @keypress="isNumber($event)"
            ></b-input>
            <span class="dash">-</span>
            <b-input
              v-model.trim="approvedMonth"
              placeholder="月"
              type="number"
              max="12"
              min="1"
              @keypress="isNumber($event)"
            ></b-input>
            <span class="dash">-</span>
            <b-input
              v-model.trim="approvedDay"
              type="number"
              max="31"
              min="1"
              placeholder="日"
              @keypress="isNumber($event)"
            ></b-input>
          </div>
        </b-input-group>

        <b-input-group
          prepend="清償年"
          style="grid-column: 3/5;display: grid;grid-template-columns: 100px 112px 153px;"
        >
          <div class="mr-1">
            <b-input-group append="年">
              <b-input
                type="number"
                max="999"
                min="0"
                v-model="settlementYear"
                placeholder="年"
              ></b-input>
              <!-- <span style="margin-left: 3px; margin-right: 3px; margin-top: 6.5px"
            >年</span
          > -->
            </b-input-group>
          </div>
          <div>
            <b-input-group append="月">
              <b-input
                type="number"
                max="11"
                min="0"
                v-model="settlementMonth"
                placeholder="月"
              ></b-input>
            </b-input-group>
          </div>
          <!-- <span style="margin-left: 3px; margin-top: 6.5px">月</span> -->
        </b-input-group>
        <b-input-group prepend="清償期別" append="月">
          <b-input
            :value="`${totalPayMonth}`"
            placeholder="清償期別"
            readonly
          ></b-input>
          <!-- <span style="margin-left: 3px; margin-top: 6.5px">月</span> -->
        </b-input-group>
        <b-input-group prepend="每月付款日">
          <b-input
            v-model="monthlyPayDate"
            placeholder="請輸入每月付款日"
            type="number"
            max="31"
            min="1"
            @keypress="isNumber($event)"
          ></b-input>
        </b-input-group>
      </main>
      <main class="main-grid-dtc2">
        <b-input-group prepend="還款期間">
          <!-- <span style="margin-left: 3px; margin-right: 3px; margin-top: 6.5px"
            >起</span
          > -->
          <div>
            <b-input-group prepend="起" class="special"> </b-input-group>
          </div>
          <div class="sign-date">
            <b-input
              v-model.trim="repaymentStartYear"
              placeholder="民國年"
              type="number"
              min="1"
              @keypress="isNumber($event)"
            ></b-input>
            <span class="dash">-</span>
            <b-input
              v-model.trim="repaymentStartMonth"
              placeholder="月"
              type="number"
              max="12"
              min="1"
              @keypress="isNumber($event)"
            ></b-input>
            <span class="dash">-</span>
            <b-input
              v-model.trim="repaymentStartDay"
              placeholder="日"
              type="number"
              max="31"
              min="1"
              @keypress="isNumber($event)"
            ></b-input>
          </div>
          <!-- <span style="margin-left: 3px; margin-right: 3px; margin-top: 6.5px"
            >迄</span
          > -->
          <div>
            <b-input-group prepend="迄" class="special"> </b-input-group>
          </div>
          <div class="sign-date">
            <b-input
              v-model.trim="repaymentEndYear"
              placeholder="民國年"
              type="number"
              min="1"
              readonly
            ></b-input>
            <span class="dash">-</span>
            <b-input
              v-model.trim="repaymentEndMonth"
              placeholder="月"
              type="number"
              max="12"
              min="1"
              readonly
            ></b-input>
            <span class="dash">-</span>
            <b-input
              v-model.trim="repaymentEndDay"
              placeholder="日"
              type="number"
              max="31"
              min="1"
              readonly
            ></b-input>
          </div>
        </b-input-group>
        <b-input-group prepend="還款總額">
          <b-input v-model="totalRepayment" placeholder="還款總額"></b-input>
        </b-input-group>
        <b-input-group prepend="第一期付款金額" class="special-col">
          <b-input v-model="firstPayRepayment"></b-input>
        </b-input-group>

        <b-input-group prepend="每月付款金額" class="special-col">
          <b-input
            v-model="monthlyPayRepayment"
            placeholder="每月金額"
            readonly
          ></b-input>
        </b-input-group>
        <b-input-group prepend="還款帳戶">
          <b-input v-model="repaymentAccount" placeholder="還款帳戶"></b-input>
        </b-input-group>
        <div></div>
      </main>
      <div style="text-align:right;margin-top:8px">
        <b-button variant="success" @click="openContract" class="mr-2"
          >檢視還款契約書</b-button
        >
        <b-button
          variant="primary"
          @click="addRepayContract"
          :disabled="!allFilled"
          >儲存契約書內容</b-button
        >
      </div>

      <hr class="my-3 new" />
      <h4 class="title">分期還款繳納紀錄</h4>
      <div class="mb-2">
        <b-button
          variant="warning"
          class="mr-2"
          @click="addRecord"
          :disabled="!allFilled"
          ><i class="fas fa-plus"></i>新增</b-button
        >
        <!-- <b-button variant="danger" class="mr-2">儲存</b-button> -->
        <b-button
          variant="primary"
          @click="$bvModal.hide('contractrepayrecord')"
          >返回</b-button
        >
      </div>
      <header class="dtc-grid-header my-dark">
        <div style="background: #424242; color: #fff">操作</div>
        <div
          v-for="(item, i) in headers"
          :key="`headers${i}`"
          :title="item"
          style="background: #424242; color: #fff"
        >
          {{ item }}
        </div>
      </header>
      <main
        v-if="!items.length"
        class="mt-2"
        style="
          grid-column: 1/-1;
          font-size: 26px;
          text-align: center;
          font-weight: 600;
        "
      >
        暫無資料
      </main>
      <main
        class="dtc-grid-header2"
        v-for="(item, i) in items"
        :key="i"
        style="color: #39312e"
        :style="
          i % 2 == 0
            ? 'background-color: #EDEDED;'
            : 'background-color: #DDDDDD;'
        "
      >
        <div>
          <b-button
            variant="primary"
            class="mr-2"
            size="sm"
            :pressed="true"
            @click="editSingleRecord(item)"
            >編輯</b-button
          >
          <b-button variant="danger" size="sm" @click="deletSingleRecord(item)"
            >刪除</b-button
          >
        </div>
        <div :title="item.period">{{ item.period }}</div>
        <div :title="item.payDate">{{ item.payDate }}</div>
        <div :title="item.payAmount">{{ $formatPrice(item.payAmount) }}</div>
        <div :title="item.remainAmount">
          {{ $formatPrice(item.remainAmount) }}
        </div>
        <div :title="item.payWay">{{ item.payWay }}</div>
        <div :title="item.receiptNumber">
          {{ $formatPrice(item.receiptNumber) }}
        </div>
        <div :title="item.remark">{{ item.remark }}</div>
      </main>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3 mr-3"
          @click="$bvModal.hide('contractrepayrecord')"
          >關閉</b-button
        >
        <!-- <b-button
          variant="success"
          class="float-right ml-3"
          @click="$bvModal.hide('contractrepayrecord')"
          >確定</b-button
        > -->
      </div>
    </template>
  </b-modal>
</template>

<script>
const headers = [
  "期別",
  "繳納日期",
  "繳納金額",
  "剩餘金額",
  "繳納方式",
  "收據號碼",
  "備註",
];
import { store } from "@/store/global.js";
import moment from "moment";
import ContractRepayRecordAdd from "./ContractRepayRecordAdd";
import ContractRepayRecordEdit from "./ContractRepayRecordEdit";

export default {
  name: "EditPersonItem",
  data() {
    return {
      item: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headers,
      items: [],

      name: "",
      id: "",
      guarantor: "",
      guarantorSecond: "",
      guardian: "",
      signingYear: "",
      signingMonth: "",
      signingDay: "",
      approvedNumber: "",
      approvedYear: "",
      approvedMonth: "",
      approvedDay: "",
      settlementYear: "",
      settlementMonth: "",
      monthlyPayDate: "",
      repaymentStartYear: "",
      repaymentStartMonth: "",
      repaymentStartDay: "",
      repaymentEndYear: "",
      repaymentEndMonth: "",
      repaymentEndDay: "",
      totalRepayment: "",
      firstPayRepayment: "",
      monthlyPayRepayment: "",
      repaymentAccount: "",
      amount: 0,
      seqNumber: "",
    };
  },
  components: {
    ContractRepayRecordAdd,
    ContractRepayRecordEdit,
  },
  computed: {
    allFilled() {
      let r =
        Boolean(this.name) &&
        Boolean(this.id) &&
        Boolean(this.guarantor) &&
        Boolean(this.signingYear) &&
        Boolean(this.signingMonth) &&
        Boolean(this.signingDay) &&
        Boolean(this.approvedNumber) &&
        Boolean(this.approvedYear) &&
        Boolean(this.approvedMonth) &&
        Boolean(this.approvedDay) &&
        Boolean(this.totalPayMonth) &&
        // Boolean(this.settlementYear) &&
        // Boolean(this.settlementMonth) &&
        Boolean(this.monthlyPayDate) &&
        Boolean(this.repaymentStartYear) &&
        Boolean(this.repaymentStartMonth) &&
        Boolean(this.repaymentStartDay) &&
        Boolean(this.totalRepayment) &&
        Boolean(this.firstPayRepayment) &&
        Boolean(this.repaymentAccount);
      return r;
    },
    totalPayMonth() {
      return (
        (Boolean(this.settlementYear) ? +this.settlementYear * 12 : 0) +
        (Boolean(this.settlementMonth) ? +this.settlementMonth : 0)
      );
    },
    endCondition() {
      return (
        Boolean(this.totalPayMonth) &&
        Boolean(this.repaymentStartYear) &&
        Boolean(this.repaymentStartMonth) &&
        Boolean(this.repaymentStartDay)
      );
    },
    startPayDate() {
      return `${this.repaymentStartYear}-${this.repaymentStartMonth}-${this.repaymentStartDay}`;
    },
    totalRepaymentNum() {
      return Boolean(this.totalRepayment)
        ? this.totalRepayment.toString().replace(/,/g, "")
        : 0;
    },
    firstRepaymentNum() {
      return Boolean(this.firstPayRepayment)
        ? +this.firstPayRepayment.toString().replace(/,/g, "")
        : 0;
    },
    monthlyPaymentCondition() {
      return (
        Boolean(this.totalRepaymentNum) &&
        Boolean(this.firstRepaymentNum) &&
        Boolean(this.totalPayMonth)
      );
    },
  },
  methods: {
    openContract() {
      store.editItem = { ...this.item };
      if (store.editItem.type == 1) {
        store.nowIndex = 3;
      } else {
        store.nowIndex = 4;
      }
      this.$router.push("contractview");
    },
    async deletSingleRecord(item) {
      const url = `?seqno=${+this.seqNumber}&times=${item.period}`;
      try {
        await window.axios.delete("StudentInfo/DeleteRepaymentDetail" + url);
        this.$bvModal.hide("repayRecordAdd");
        this.$bvToast.toast(`刪除成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
      } catch (e) {
        this.$bvToast.toast(`刪除失敗` + e, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    editSingleRecord(item) {
      store.editItem = { ...item };
      store.editItem.seqNumber = +this.seqNumber;
      this.$bvModal.show("repayRecordEdit");
    },
    async addRepayContract() {
      const obj = {
        Seqno: +this.seqNumber,
        Identifier: this.id,
        StudentName: this.name,
        GuarantorName: this.guarantor,
        WarrantorName: this.guarantorSecond,
        Guardian: this.guardian,
        ContractDate: this.$usDate(
          `${this.signingYear}-${this.signingMonth}-${this.signingDay}`
        ),
        StartDate: this.$usDate(this.startPayDate),
        EndDate: this.$usDate(
          `${this.repaymentEndYear}-${this.repaymentEndMonth}-${this.repaymentEndDay}`
        ),
        ApproveDate: this.$usDate(
          `${this.approvedYear}-${this.approvedMonth}-${this.approvedDay}`
        ),
        ApproveNo: this.approvedNumber,
        TotalMoney: this.totalRepaymentNum,
        Ryear: this.settlementYear,
        Rtimes: this.totalPayMonth,
        Rday: this.monthlyPayDate,
        First_Fee: this.firstRepaymentNum,
        Month_Fee: this.monthlyPayRepayment.toString().replace(/,/g, ""),
        BankAccount: this.repaymentAccount,
      };
      try {
        await window.axios.put("StudentInfo/PutRepayment", obj);
        this.$bvToast.toast(`新增成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast(e + ``, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateItem() {
      this.item = { ...store.editItem };
      this.name = this.item.name;
      this.id = this.item.id;
      this.guarantor = "";
      this.guarantorSecond = "";
      this.guardian = "";
      this.signingYear = "";
      this.signingMonth = "";
      this.signingDay = "";
      this.approvedNumber = "";
      this.approvedYear = "";
      this.approvedMonth = "";
      this.approvedDay = "";
      this.settlementYear = "";
      this.settlementMonth = "";
      this.monthlyPayDate = "";
      this.repaymentStartYear = "";
      this.repaymentStartMonth = "";
      this.repaymentStartDay = "";
      this.repaymentEndYear = "";
      this.repaymentEndMonth = "";
      this.repaymentEndDay = "";
      this.totalRepayment = "";
      this.firstPayRepayment = "";
      this.monthlyPayRepayment = "";
      this.repaymentAccount = "";
      this.amount = 0;
      this.seqNumber = "";
      this.getData();
    },
    addRecord() {
      const allItem = {
        Identifier: this.id,
        StudentName: this.name,
        GuarantorName: this.guarantor,
        WarrantorName: this.guarantorSecond,
        Guardian: this.guardian,
        ContractDate: this.$usDate(
          `${this.signingYear}-${this.signingMonth}-${this.signingDay}`
        ),
        StartDate: this.$usDate(this.startPayDate),
        EndDate: this.$usDate(
          `${this.repaymentEndYear}-${this.repaymentEndMonth}-${this.repaymentEndDay}`
        ),
        ApproveDate: this.$usDate(
          `${this.approvedYear}-${this.approvedMonth}-${this.approvedDay}`
        ),
        ApproveNo: this.approvedNumber,
        TotalMoney: this.totalRepaymentNum,
        Ryear: this.settlementYear,
        Rtimes: this.totalPayMonth,
        Rday: this.monthlyPayDate,
        First_Fee: this.firstRepaymentNum,
        Month_Fee: this.monthlyPayRepayment.toString().replace(/,/g, ""),
        BankAccount: this.repaymentAccount,
        Seqno: this.seqNumber,
      };

      this.item.allItem = allItem;
      this.item.totalPayMonth = this.totalPayMonth;
      this.item.nowAmount = this.amount;
      store.editItem = { ...this.item };
      this.$bvModal.show("repayRecordAdd");
    },
    setEndPayDate() {
      const twStartDate = `${this.repaymentStartYear}-${this.repaymentStartMonth}-${this.repaymentStartDay}`;
      const usStartDate = this.$usDate(twStartDate);
      const usEndDate = moment(usStartDate)
        .add(this.totalPayMonth, "months")
        .format("YYYY-MM-DD");
      const twEndDate = this.$twDate2(usEndDate);
      this.repaymentEndYear = +twEndDate.split("-")[0];
      this.repaymentEndMonth = twEndDate.split("-")[1];
      this.repaymentEndDay = twEndDate.split("-")[2];
    },
    setMonthlyPay() {
      this.monthlyPayRepayment =
        (this.totalRepaymentNum - this.firstRepaymentNum) /
        (this.totalPayMonth - 1);
      this.monthlyPayRepayment = this.$formatPrice(this.monthlyPayRepayment);
    },
    async getData() {
      const id = this.id;
      const url = `StudentInfo/GetRepayment?identifier=${id}`;

      try {
        const res = await window.axios.get(url);
        const arr = res ? res.RepaymentDetails : [];
        if (arr.length) {
          this.items = arr.map((s) => ({
            period: s.Times,
            payDate: this.$twDate(s.Pay_Date),
            payAmount: s.Pay_Fee,
            remainAmount: s.Fee_2,
            payWay: s.Pay_Kind,
            receiptNumber: s.AccNo,
            remark: s.Commet,
          }));
        } else {
          this.items = arr;
        }
        this.setDetailRecord(res);
        this.amount = arr.length;
        this.seqNumber = res.Seqno;
      } catch (e) {
        // this.$bvToast.toast(e + ``, {
        //   title: "系統資訊",
        //   autoHideDelay: 5000,
        //   variant: "danger",
        // });
      }
    },
    setDetailRecord(res) {
      const obj = { ...res };
      this.guarantor = obj.GuarantorName || "";
      this.guarantorSecond = obj.WarrantorName || "";
      this.guardian = obj.Guardian || "";
      this.signingYear = obj.ContractDate
        ? this.$twDate(obj.ContractDate).split("-")[0]
        : "";
      this.signingMonth = obj.ContractDate
        ? this.$twDate(obj.ContractDate).split("-")[1]
        : "";
      this.signingDay = obj.ContractDate
        ? this.$twDate(obj.ContractDate).split("-")[2]
        : "";
      this.approvedNumber = obj.ApproveNo || "";
      this.approvedYear = obj.ApproveDate
        ? this.$twDate(obj.ApproveDate).split("-")[0]
        : "";
      this.approvedMonth = obj.ApproveDate
        ? this.$twDate(obj.ApproveDate).split("-")[1]
        : "";
      this.approvedDay = obj.ApproveDate
        ? this.$twDate(obj.ApproveDate).split("-")[2]
        : "";
      this.settlementYear = obj.Ryear || "";
      this.settlementMonth =
        Boolean(obj.Rtimes) && Boolean(obj.Ryear)
          ? +obj.Rtimes - +obj.Ryear * 12
          : 0;
      this.monthlyPayDate = obj.Rday || "";
      this.repaymentStartYear = obj.StartDate
        ? this.$twDate(obj.StartDate).split("-")[0]
        : "";
      this.repaymentStartMonth = obj.StartDate
        ? this.$twDate(obj.StartDate).split("-")[1]
        : "";
      this.repaymentStartDay = obj.StartDate
        ? this.$twDate(obj.StartDate).split("-")[2]
        : "";
      this.totalRepayment = obj.TotalMoney || "";
      this.firstPayRepayment = obj.First_Fee || "";
      this.monthlyPayRepayment = obj.Month_Fee || "";
      this.repaymentAccount = obj.BankAccount || "";
    },
  },
  mounted() {
    // const fakeData = [
    //   {
    //     period: 1,
    //     payDate: "109-05-23",
    //     payAmount: 53900,
    //     remainAmount: 502000,
    //     payWay: "匯款",
    //     receiptNumber: 5705012,
    //     remark: "備註內容1",
    //   },
    //   {
    //     period: 2,
    //     payDate: "109-05-27",
    //     payAmount: 40900,
    //     remainAmount: 402000,
    //     payWay: "匯款",
    //     receiptNumber: 5705013,
    //     remark: "備註內容2",
    //   },
    // ];
    // this.items = fakeData;
  },
  watch: {
    monthlyPayDate(v) {
      if (v) {
        let value = `${v}`;
        if (+v < 0) {
          this.monthlyPayDate = 0;
        }
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (+v >= 31) {
          value = 31;
        }
        const result = value;
        this.$nextTick(() => (this.monthlyPayDate = result));
      }
    },
    signingYear(v) {
      if (v) {
        if (+v < 0) {
          this.signingYear = "0";
        }
        let value = `${v}`;
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (v.length > 3) {
          value = value.substring(1, 4);
        }
        const result = value;
        this.$nextTick(() => (this.signingYear = result));
      }
    },
    signingMonth(v) {
      if (v) {
        let value = `${v}`;
        if (+v < 0) {
          this.signingMonth = 0;
        }
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (+v == 10) {
          value = 10;
        } else if (+v == 11) {
          value = 11;
        } else if (+v >= 12) {
          value = 12;
        }
        const result = value;
        this.$nextTick(() => (this.signingMonth = result));
      }
    },
    signingDay(v) {
      if (v) {
        let value = `${v}`;
        if (+v < 0) {
          this.signingDay = 0;
        }
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (+v >= 31) {
          value = 31;
        }
        const result = value;
        this.$nextTick(() => (this.signingDay = result));
      }
    },
    approvedYear(v) {
      if (v) {
        if (+v < 0) {
          this.approvedYear = "0";
        }
        let value = `${v}`;
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (v.length > 3) {
          value = value.substring(1, 4);
        }
        const result = value;
        this.$nextTick(() => (this.approvedYear = result));
      }
    },
    approvedMonth(v) {
      if (v) {
        let value = `${v}`;
        if (+v < 0) {
          this.approvedMonth = 0;
        }
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (+v == 10) {
          value = 10;
        } else if (+v == 11) {
          value = 11;
        } else if (+v >= 12) {
          value = 12;
        }
        const result = value;
        this.$nextTick(() => (this.approvedMonth = result));
      }
    },
    approvedDay(v) {
      if (v) {
        let value = `${v}`;
        if (+v < 0) {
          this.approvedDay = 0;
        }
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (+v >= 31) {
          value = 31;
        }
        const result = value;
        this.$nextTick(() => (this.approvedDay = result));
      }
    },

    repaymentStartYear(v) {
      if (v) {
        if (+v < 0) {
          this.repaymentStartYear = "0";
        }
        let value = `${v}`;
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (v.length > 3) {
          value = value.substring(1, 4);
        }
        const result = value;
        this.$nextTick(() => (this.repaymentStartYear = result));
      }
    },
    repaymentStartMonth(v) {
      if (v) {
        let value = `${v}`;
        if (+v < 0) {
          this.repaymentStartMonth = 0;
        }
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (+v == 10) {
          value = 10;
        } else if (+v == 11) {
          value = 11;
        } else if (+v >= 12) {
          value = 12;
        }
        const result = value;
        this.$nextTick(() => (this.repaymentStartMonth = result));
      }
    },
    repaymentStartDay(v) {
      if (v) {
        let value = `${v}`;
        if (+v < 0) {
          this.repaymentStartDay = 0;
        }
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (+v >= 31) {
          value = 31;
        }
        const result = value;
        this.$nextTick(() => (this.repaymentStartDay = result));
      }
    },

    settlementYear(v) {
      if (v) {
        if (+v < 0) {
          this.settlementYear = "0";
        }
        let value = `${v}`;
        if (v.length > 3) {
          value = value.substring(1, 4);
        }
        const result = value;
        this.$nextTick(() => (this.settlementYear = result));
      }
    },
    settlementMonth(v) {
      if (v) {
        if (+v < 0) {
          this.settlementMonth = 0;
        }
        let value = v;
        if (+v == 10) {
          value = 10;
        } else if (v.length == 2) {
          value = 11;
        } else if (v.length > 2) {
          value = 11;
        }
        const result = value;
        this.$nextTick(() => (this.settlementMonth = result));
      }
    },
    endCondition(v) {
      if (v) {
        this.setEndPayDate();
      }
    },
    totalPayMonth(v) {
      if (this.endCondition) {
        this.setEndPayDate();
      }
      if (this.monthlyPaymentCondition) {
        this.setMonthlyPay();
      }
    },
    startPayDate(v) {
      if (this.endCondition) {
        this.setEndPayDate();
      }
    },
    totalRepayment(v) {
      if (v) {
        let value = `${v}`;
        if (v.length > 10) {
          value = value.substring(0, 10);
        }

        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        const result = value
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.$nextTick(() => (this.totalRepayment = result));
      }
    },
    firstPayRepayment(v) {
      if (v) {
        let value = `${v}`;
        if (v.length > 10) {
          value = value.substring(0, 10);
        }

        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        const result = value
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.$nextTick(() => (this.firstPayRepayment = result));
      }
    },
    totalRepaymentNum(v) {
      if (this.monthlyPaymentCondition) {
        this.setMonthlyPay();
      }
    },
    firstRepaymentNum(v) {
      if (this.monthlyPaymentCondition) {
        this.setMonthlyPay();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main-grid-dtc,
.main-grid-dtc2 {
  display: grid;
  grid-template-columns: 200px 309px 180px 180px 351px 310px;
  grid-column-gap: 6px;
}
.main-grid-dtc2 {
  grid-template-columns: 616px 222px repeat(3, 232.5px);
  height: 38px;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 120px 80px repeat(5, 140px) 1fr;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    // word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    word-break: keep-all;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-top: 1px solid #dee2e5;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header2 {
  grid-template-columns: 120px 80px repeat(5, 140px) 1fr;
  > div {
    font-size: 14px;
    padding: 0;
    padding-top: 6px;
  }
}
p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 100px;
  background: #0379fd;
  color: #fff;
  height: 38px;
}
div.input-group-append > div.input-group-text {
  width: 50px;
  background: #fafafa;
  color: rgb(77, 77, 77);
  height: 38px;
}
div.input-group.special > div > div.input-group-text {
  width: 46px;
  margin-left: 3px;
}

div.input-group.special-col > div.input-group-prepend > div.input-group-text {
  width: 130px;
}
div.input-group.dtc-bg > div.input-group-prepend > div.input-group-text {
  background: #646969;
  color: #ffffff;
}

.input-group {
  margin-bottom: 10px;
}

::v-deep #contractrepayrecord > .modal-dialog > .modal-content > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
::v-deep .modal-content {
  margin-left: -226px;
  width: 142.4%;
}

hr.new {
  border-top: 1px dashed #cacaca;
}
h4.title {
  font-size: 20px;
  font-weight: 700;
}
.sign-date {
  display: grid;
  grid-template-columns: 67px 12.5px 59px 12.5px 59px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
</style>
